import React from "react"
import Layout from "../components/Layout"
import Main from "../components/Main"

const Index = () => {
  return (
    <Layout>
      <Main />
    </Layout>
  )
}
export default Index;